import React from 'react';
import PropTypes from 'prop-types';

import styleArrow from './utils/style-arrow';
import colors from '../../config/colors';
import dimensions from '../../config/dimensions';

function UnStyledArrow({ className, size, color, clickable, onClick }) {
    function handleClick() {
        if (onClick && clickable) onClick();
    }

    return (
        <svg
            className={className}
            viewBox='0 0 14 8'
            width={dimensions.arrow.width[size]}
            height={dimensions.arrow.height[size]}
            onClick={handleClick}
        >
            <path
                fill='none'
                stroke={colors[color]}
                strokeWidth='1'
                d='M0.7,7.4l6.3,6.3l6.3-6.3'
                transform='translate(0 -7)'
            />
        </svg>
    );
}

const StyledArrow = styleArrow(UnStyledArrow);

export function Arrow({ variant = 'left', size = 'md', color = 'arrow', clickable = true, ...restProps }) {
    return <StyledArrow variant={variant} size={size} color={color} clickable={clickable} {...restProps} />;
}
Arrow.propTypes = {
    variant: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xxl']),
    color: PropTypes.oneOf(['arrow', 'primary']),
    clickable: PropTypes.bool,
    onClick: PropTypes.func
};
