import React from 'react';
import styled from 'styled-components';

const Date = ({ testId, id, placeholder, name, dataCy, disabled, required, onChange, defaultValue }) => {
    return (
        <input
            data-testid={testId}
            id={id}
            data-cy={dataCy}
            type='date'
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            required={required}
            defaultValue={defaultValue}
            onChange={({ target }) =>
                onChange({
                    name,
                    value: target.value ? target.valueAsDate : null
                })
            }
        />
    );
};

export default styled(Date)``;
