import { useState, useEffect, useRef } from 'react';

export default function (trigger, content) {
    const [visibility, setVisibility] = useState('');
    const visibilityRef = useRef();

    function handleClick() {
        if (!content) return;

        if (trigger === 'click') {
            const updatedVisibility = toggleVisibility(visibility);
            updateVisibility(updatedVisibility);
        }
    }

    function updateVisibility(updatedVisibility) {
        setVisibility(updatedVisibility);
        visibilityRef.current = updatedVisibility;
    }

    function handleMouseEnter() {
        if (!content) return;

        if (trigger === 'hover') updateVisibility('shown');
    }

    function handleMouseLeave() {
        if (!content) return;

        if (trigger === 'hover') updateVisibility('hidden');
    }

    function handleClickOnDOM() {
        if (!content) return;

        if (visibilityRef.current === 'shown') updateVisibility('hidden');
    }

    useEffect(() => {
        if (trigger === 'click' && visibility === 'shown') window.addEventListener('click', handleClickOnDOM);
        return () => {
            if (trigger === 'click') window.removeEventListener('click', handleClickOnDOM);
        };
    }, [trigger, visibility]);

    useEffect(() => {
        if (!content && visibilityRef.current === 'shown') updateVisibility('hidden');
    }, [content]);

    return { visibility, handleClick, handleMouseEnter, handleMouseLeave };
}

function toggleVisibility(visibility) {
    const toggleValues = { shown: 'hidden', hidden: 'shown' };

    return toggleValues[visibility] || toggleValues.hidden;
}
