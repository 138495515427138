export default {
    hide: -1,
    auto: 'auto',
    base: 0,
    autocomplete: 1,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    dialog: 1700,
    tooltip: 1800
};
