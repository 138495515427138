import React, { useState, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { MAIN_TABS_TEST_ID } from '../utils/tests-ids';

import colors from '../config/colors';

// main tabs item
const UnStyledMainTab = ({ className, children }) => {
    return <div className={className}>{children}</div>;
};

const StyledMainTab = styled(UnStyledMainTab)`
    display: ${(props) => (props.activeKey === props.tabKey ? 'block' : 'none')};
    padding: 1rem 0;
`;

const MainTab = (props) => <StyledMainTab {...props} />;

MainTab.propTypes = {
    title: PropTypes.string.isRequired,
    tabKey: PropTypes.string.isRequired,
    activeKey: PropTypes.string
};

// main tabs
const UnStyledMainTabs = ({ className, children, defaultTab }) => {
    const tabs = React.Children.toArray(children).map((child) => ({
        title: child.props.title,
        tabKey: child.props.tabKey
    }));
    if (tabs.length === 0) throw new Error('You need to provide at least one element');

    const [activeKey, setActiveKey] = useState(defaultTab || tabs[0].tabKey);

    return (
        <div className={className} data-testid={MAIN_TABS_TEST_ID}>
            <header>
                <nav>
                    <ul>
                        {tabs.map((tab) => (
                            <li role='navigation' aria-label={tab.title} key={tab.tabKey} className={activeKey === tab.tabKey ? 'active' : ''} onClick={() => setActiveKey(tab.tabKey)}>
                                {tab.title}
                            </li>
                        ))}
                    </ul>
                </nav>
            </header>
            <main>{React.Children.map(children, (child) => isValidElement(child) && cloneElement(child, { activeKey }))}</main>
        </div>
    );
};

const StyledMainTabs = styled(UnStyledMainTabs)`
    > header {
        > nav {
            position: relative;

            ::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                background: #d6dce9;
            }

            > ul {
                list-style: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;

                > li {
                    padding: 0.6rem 0.75rem;
                    margin: 0 1.25rem;
                    position: relative;
                    color: var(--color-input-label);
                    font-size: 0.813rem;
                    cursor: pointer;
                    transition: all 50ms ease;

                    :hover {
                        opacity: 0.7;
                    }

                    &.active {
                        color: ${colors.primary};
                        font-weight: 600;

                        ::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 2px;
                            background: var(--color-primary-light-3);
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
`;

const MainTabs = (props) => <StyledMainTabs {...props} />;

MainTabs.propTypes = {
    defaultTab: PropTypes.string
};

MainTabs.defautltProps = {
    defaultTab: null
};

MainTabs.Item = MainTab;

export default MainTabs;
