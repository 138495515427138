import styled from 'styled-components';

import dimensions from '../../../config/dimensions';
import colors from '../../../config/colors';
import zIndices from '../../../config/z-indices';

const { arrowBorderWidth } = dimensions.tooltip;

export default function (UnStyledPopover) {
    return styled(UnStyledPopover)`
        display: ${(props) => getDisplay(props)};
        position: relative;
        > div:first-child {
            z-index: ${zIndices.tooltip};
            position: absolute;
            width: ${(props) => props.popoverWidth};
            visibility: hidden;
            top: ${(props) => toPercentage(getContainerTop(props), props.adjustPositioningConstant, 'top', props.placement)};
            right: ${(props) => toPercentage(getContainerRight(props), props.adjustPositioningConstant, 'right', props.placement)};
            bottom: ${(props) => toPercentage(getContainerBottom(props), props.adjustPositioningConstant, 'bottom', props.placement)};
            left: ${(props) => toPercentage(getContainerLeft(props), props.adjustPositioningConstant, 'left', props.placement)};
            background: transparent;
            padding: 1.5rem;
            background: ${(props) => getBubbleBackgroundColor(props)};
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            filter: drop-shadow(0 0 4px rgba(144, 177, 225, 0.38));
            ::before {
                content: '';
                position: absolute;
                top: ${(props) => getBubbleTop(props)};
                right: ${(props) => getBubbleRight(props)};
                bottom: ${(props) => getBubbleBottom(props)};
                left: ${(props) => getBubbleLeft(props)};
                border-top: ${(props) => getArrowBorderTop(props)};
                border-right: ${(props) => getArrowBorderRight(props)};
                border-bottom: ${(props) => getArrowBorderBottom(props)};
                border-left: ${(props) => getArrowBorderLeft(props)};
                margin: ${(props) => getArrowMargin(props)};
                transform: ${(props) => getBubbleTransform(props)};
            }
        }
        &.shown {
            > div:first-child {
                transform: scale(1);
                opacity: 1;
                visibility: visible;
            }
        }
        &.hidden {
            > div:first-child {
                transform: scale(0);
                opacity: 0;
                visibility: hidden;
            }
        }
    `;
}

function getDisplay({ fluid }) {
    return fluid ? 'block' : 'inline-block';
}

const toPercentage = (value, constant, type, placement) => {
    const operation = {
        top: {
            top: value,
            left: value * constant,
            right: value * constant,
            bottom: value
        },
        bottom: {
            top: value,
            left: value * constant,
            right: value * constant,
            bottom: value
        },
        left: {
            top: value * constant,
            left: value,
            right: value,
            bottom: value
        },
        right: {
            top: value * constant,
            left: value,
            right: value,
            bottom: value
        }
    };
    return value === 'initial' ? 'initial' : `${operation[placement][type]}%`;
};

function getContainerTop({ placement }) {
    return { top: 'initial', right: '-50', bottom: '150', left: '-40' }[placement];
}

function getContainerRight({ placement }) {
    return { top: 'initial', right: 'initial', bottom: '-25', left: '150' }[placement];
}

function getContainerBottom({ placement }) {
    return { top: '150', right: 'initial', bottom: 'initial', left: 'initial' }[placement];
}

function getContainerLeft({ placement }) {
    return { top: '-50', right: '150', bottom: 'initial', left: 'initial' }[placement];
}

function getBubbleBackgroundColor() {
    return colors.white;
}

function getBubbleTop({ placement, isArrowCentered }) {
    const params = { top: () => '100%', right: () => (isArrowCentered ? '50%' : 'initial'), bottom: () => 'initial', left: () => (isArrowCentered ? '50%' : 'initial') };
    return params[placement]();
}

function getBubbleRight({ placement, isArrowCentered }) {
    const params = { top: () => `${isArrowCentered ? 100 / 2 : 'initial'}%`, right: () => `100%`, bottom: () => (isArrowCentered ? '50%' : '0'), left: () => 'initial' };
    return params[placement]();
}

function getBubbleBottom({ placement, isArrowCentered }) {
    const params = { top: () => `initial`, right: () => 'initial', bottom: () => `100%`, left: () => 'initial' };
    return params[placement]();
}

function getBubbleLeft({ placement, isArrowCentered }) {
    const params = { top: () => `initial`, right: () => 'initial', bottom: () => `initial`, left: () => `100%` };
    return params[placement]();
}

function getBubbleTransform({ placement }) {
    return ['left', 'right'].includes(placement) ? 'translateY(-50%)' : 'translateX(-50%)';
}

function getArrowBorderTop({ placement, type }) {
    return {
        top: `${arrowBorderWidth} solid ${getBubbleBackgroundColor({ type })}`,
        right: `${arrowBorderWidth} solid transparent`,
        bottom: 'none',
        left: `${arrowBorderWidth} solid transparent`
    }[placement];
}

function getArrowBorderRight({ placement, type }) {
    return {
        top: `${arrowBorderWidth} solid transparent`,
        right: `${arrowBorderWidth} solid ${getBubbleBackgroundColor({ type })}`,
        bottom: `${arrowBorderWidth} solid transparent`,
        left: 'initial'
    }[placement];
}

function getArrowBorderBottom({ placement, type }) {
    return {
        top: 'none',
        right: `${arrowBorderWidth} solid transparent`,
        bottom: `${arrowBorderWidth} solid ${getBubbleBackgroundColor({ type })}`,
        left: `${arrowBorderWidth} solid transparent`
    }[placement];
}

function getArrowBorderLeft({ placement, type }) {
    return {
        top: `${arrowBorderWidth} solid transparent`,
        right: 'none',
        bottom: `${arrowBorderWidth} solid transparent`,
        left: `${arrowBorderWidth} solid ${getBubbleBackgroundColor({ type })}`
    }[placement];
}

function getArrowMargin({ placement }) {
    return { top: '0 0 0 0', right: '0 0 0 0', bottom: '0 0 0 0', left: '0 0 2rem 0' }[placement];
}
