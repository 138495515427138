import styled from 'styled-components';

import colors from '../../../config/colors';
import { fontSizes } from '../../../config/sizes';
import { getColor } from '../../../utils/component';

export default function (UnStyledHeaderCard) {
    return styled(UnStyledHeaderCard)`
        padding: 0.5rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: ${(props) => getBackgroundColor(props)};

        > main {
            color: ${(props) => getTextColor(props)};

            span {
                display: inline-block;
                line-height: 1.15;
            }

            > div {
                display: flex;
                align-items: baseline;

                > span {
                    :nth-child(1) {
                        font-size: ${(props) => getTitleFontSize(props)};
                        font-weight: ${(props) => getTitleFontWeight(props)};
                    }

                    :nth-child(2) {
                        font-size: ${(props) => getLabelFontSize(props)};
                        font-weight: ${(props) => getLabelFontWeight(props)};
                        margin-left: 0.5rem;
                    }
                }
            }

            > span {
                font-size: ${fontSizes.sm};
                margin-top: 0.5rem;
            }
        }

        > footer {
            margin-top: 0.35rem;
        }
    `;
}

function getBackgroundColor({ variant, color, lightMode }) {
    if (variant === 'ghost') return colors.white;

    return getColor(color, lightMode);
}

function getTitleFontSize({ titleVariant }) {
    return { 1: fontSizes.xl, 2: fontSizes.lg }[titleVariant];
}

function getTitleFontWeight({ titleVariant }) {
    return { 1: 600, 2: 500 }[titleVariant];
}

function getLabelFontSize({ labelVariant }) {
    return { 1: fontSizes.lg, 2: fontSizes.sm }[labelVariant];
}

function getLabelFontWeight({ labelVariant }) {
    return { 1: 600, 2: 400 }[labelVariant];
}

function getTextColor({ variant, color, lightMode }) {
    return variant === 'filled' ? colors.white : getColor(color, lightMode);
}
