import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidV1 } from 'uuid';

import styleField from './utils/style-field';
import { canShowLabel, getAutoComplete } from './utils';
import { capitalize, toUpperCase, toLowerCase } from '../../utils/text';

import { FieldError } from './FieldError';
import Text from '../inputs/Text';
import Password from '../inputs/Password';
import Email from '../inputs/Email';
import Phone from '../inputs/Phone';
import Date from '../inputs/Date';
import NumberInput, { ControlledNumber } from '../inputs/Number';
import Select from '../inputs/Select';
import Radio from '../inputs/Radio';
import RadioGroup from '../inputs/RadioGroup';
import Checkbox, { ControlledCheckbox } from '../inputs/Checkbox';
import Textarea from '../inputs/Textarea';
import useField from './hooks/useField';
import { SwitchCheckboxWrapper } from './wrappers/SwitchCheckboxWrapper';
import { toNonNullString } from '../../utils';

const UnStyledField = ({ className, testId, id, type, label, name, textAppearance, placeholder, error, defaultValue, autoCompleted, disabled, onChange, onBlur, onFocus, ...restProps }) => {
    useField(id, defaultValue);
    const defaultId = useMemo(() => id, [id]);
    const defaultTestId = useMemo(() => testId, [testId]);

    const displayFieldStrategies = {
        text: displayText,
        number: displayNumber,
        email: displayEmail,
        phone: displayPhone,
        tel: displayPhone,
        date: displayDate,
        password: displayPassword,
        select: displaySelect,
        switch: displaySwitch,
        radio: displayRadio,
        'radio-group': displayRadioGroup,
        checkbox: displayCheckbox,
        textarea: displayTextarea
    };

    function handleChange(params) {
        if (onChange) onChange(params);
    }

    function handleBlur(params) {
        if (onBlur) onBlur(params);
    }

    function handleFocus(params) {
        if (onFocus) onFocus(params);
    }

    function textAppearanceStrategy(textAppearance) {
        const textAppearanceMapping = {
            capitalize,
            lowercase: toLowerCase,
            uppercase: toUpperCase,
            initial: (value) => value
        };
        return textAppearanceMapping[textAppearance];
    }

    return (
        <div className={className}>
            {canShowLabel(type, label) && <label htmlFor={defaultId}>{textAppearanceStrategy(textAppearance)(label)}</label>}
            {displayFieldStrategies[type]({
                id: defaultId,
                testId: defaultTestId,
                label,
                defaultValue,
                disabled,
                name,
                placeholder,
                dataCy: toNonNullString(name + label || placeholder),
                autoComplete: getAutoComplete(autoCompleted),
                onChange: handleChange,
                onBlur: handleBlur,
                onFocus: handleFocus,
                ...restProps
            })}
            {error && <FieldError>{error}</FieldError>}
        </div>
    );
};

function displayText(props) {
    return <Text {...props} />;
}

function displayNumber(props) {
    return Object.prototype.hasOwnProperty.call(props, 'value') ? <ControlledNumber {...props} /> : <NumberInput {...props} />;
}

function displaySwitch(props) {
    return <SwitchCheckboxWrapper>{displayCheckbox(props)}</SwitchCheckboxWrapper>;
}
function displayEmail(props) {
    return <Email {...props} />;
}

function displayPhone(props) {
    return <Phone {...props} />;
}

function displayDate(props) {
    return <Date {...props} />;
}

function displayPassword(props) {
    return <Password {...props} />;
}

function displaySelect(props) {
    return <Select {...props} />;
}

function displayRadio(props) {
    return <Radio {...props} />;
}

function displayRadioGroup(props) {
    return <RadioGroup {...props} />;
}

function displayCheckbox(props) {
    return Object.prototype.hasOwnProperty.call(props, 'checked') ? <ControlledCheckbox {...props} /> : <Checkbox {...props} />;
}

function displayTextarea(props) {
    return <Textarea {...props} />;
}

const StyledField = styleField(UnStyledField);

export const Field = ({
    testId = uuidV1(),
    id = uuidV1(),
    type = 'text',
    fluid = false,
    required = false,
    disabled = false,
    min = 0,
    max = 0,
    options = [],
    size = 'none',
    orientation = 'horizontal',
    clickable = true,
    lightBackground = false,
    autoCompleted = false,
    textAppearance = 'capitalize',
    maxHandled = false,
    showLabel = true,
    verticalAlign = 'center',
    preventDecimal = false,
    styles = {},
    ...restProps
}) => {
    return (
        <StyledField
            testId={testId}
            id={id}
            type={type}
            fluid={fluid}
            required={required}
            disabled={disabled}
            min={min}
            max={max}
            options={options}
            size={size}
            orientation={orientation}
            clickable={clickable}
            lightBackground={lightBackground}
            autoCompleted={autoCompleted}
            textAppearance={textAppearance}
            maxHandled={maxHandled}
            showLabel={showLabel}
            verticalAlign={verticalAlign}
            styles={styles}
            preventDecimal={preventDecimal}
            {...restProps}
        />
    );
};
Field.propTypes = {
    className: PropTypes.string,
    testId: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'email', 'switch', 'password', 'phone', 'tel', 'date', 'select', 'radio', 'checkbox', 'radio-group', 'textarea']),
    name: PropTypes.string,
    label: PropTypes.node,
    textAppearance: PropTypes.oneOf(['capitalize', 'uppercase', 'lowercase', 'initial']),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
            render: PropTypes.any,
            isSelected: PropTypes.func
        })
    ),
    customOption: PropTypes.node,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fluid: PropTypes.bool,
    required: PropTypes.bool,
    defaultValue: PropTypes.any,
    defaultChecked: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    error: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'none']),
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    verticalAlign: PropTypes.oneOf(['center', 'start']),
    clickable: PropTypes.bool,
    lightBackground: PropTypes.bool,
    autoCompleted: PropTypes.bool,
    maxHandled: PropTypes.bool,
    preventDecimal: PropTypes.bool,
    styles: PropTypes.shape({
        borderColor: PropTypes.oneOf(['default', 'white']),
        labelColor: PropTypes.oneOf(['default', 'primary'])
    }),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
};
