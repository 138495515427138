import { useState, useEffect, useRef } from 'react';

export default function () {
    const [visibility, setVisibility] = useState('');
    const visibilityRef = useRef();

    function handleTriggerClick() {
        const updatedVisibility = toggleVisibility(visibility);
        setVisibility(updatedVisibility);
        visibilityRef.current = updatedVisibility;
    }

    function handleClickOnDOM() {
        if (visibilityRef.current === 'shown') setVisibility('hidden');
    }

    useEffect(() => {
        if (visibility === 'shown') window.addEventListener('click', handleClickOnDOM);
        return () => {
            window.removeEventListener('click', handleClickOnDOM);
        };
    }, [visibility]);

    return { visibility, handleTriggerClick };
}

function toggleVisibility(visibility) {
    const toggleValues = { shown: 'hidden', hidden: 'shown' };

    return toggleValues[visibility] || toggleValues.hidden;
}
