import styled from 'styled-components';

export default function (UnStyledTableActions) {
    return styled(UnStyledTableActions)`
        display: flex;
        justify-content: center;
        align-items: center;

        > * {
            margin-right: 0.5rem;

            :last-child {
                margin-right: 0;
            }
        }
        ${getTwoActionsTabStyle}
    `;
}

function getTwoActionsTabStyle(props) {
    return props.twoActionsTab
        ? `> div {
        margin-left: 1rem;
    }`
        : '';
}
