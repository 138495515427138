import styled from 'styled-components';

import colors from '../../../config/colors';
import { getCursor } from '../../../utils/button';

export default function (UnStyledMenuItem) {
    return styled(UnStyledMenuItem)`
        padding: 0.75rem 1rem;
        color: ${(props) => getTextColor(props)};
        background: ${(props) => getBackground(props)};
        cursor: ${(props) => getCursor(props)};
        transition: all 200ms ease-in;

        &:hover {
            background: ${(props) => getBackground(props, true)};
        }
    `;
}

function getTextColor({ disabled }) {
    return disabled ? colors.disabled : colors.black;
}

function getBackground(props, hovered = false) {
    const { disabled } = props;

    return disabled ? colors.white : hovered ? colors.popover.item : colors.white;
}
