import React, { isValidElement, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextBold from './TextBold';

import { Card } from './card';
import mailIcon from '../assets/img/cards/blocs/mail.svg';
import attachementIcon from '../assets/img/picto_attachement.svg';
import { emailSentStatuses } from '../utils/enums';

import Status from './Status';

function UnStyledMailCard({ testId, className, title, date, financement, status, attachments, body, actionView, actionViewVisible, PDFViewer }) {
    const [isExtended, setIsExtended] = useState(false);
    const [attachementIndex, setAttachementIndex] = useState(-1);

    function handleClickArrow() {
        setAttachementIndex(-1);
        setIsExtended(!isExtended);
    }

    function handleClickAttachment(index) {
        if (index === attachementIndex) {
            setAttachementIndex(-1);
            setIsExtended(!isExtended);
        } else {
            setAttachementIndex(index);
            setIsExtended(true);
        }
    }

    function getBody() {
        if (attachementIndex !== -1) return <PDFViewer downloadUrl={attachments[attachementIndex].url} />;
        if (body === 'Mail content not available') return <TextBold>Contenu du mail indisponible</TextBold>;
        return <div dangerouslySetInnerHTML={{ __html: body }} />;
    }

    return (
        <Card testId={testId}>
            <div className={className}>
                <header>
                    <div>
                        <div>
                            <img src={mailIcon} alt='' />
                            <TextBold color='primary' fontWeight={700}>
                                Objet :
                            </TextBold>
                            <TextBold.Label>{title}</TextBold.Label>
                        </div>
                        <div>
                            <TextBold color='primary' fontWeight={700}>
                                Envoyé le :
                            </TextBold>
                            <TextBold.Label>{date}</TextBold.Label>
                        </div>
                    </div>
                    {actionViewVisible && isValidElement(actionView) && actionView}
                </header>

                <main>
                    <div>
                        <div>
                            {attachments && attachments.length > 0 && <img src={attachementIcon} alt='' />}
                            {attachments &&
                                attachments.map((attachement, index) => (
                                    <React.Fragment key={index}>
                                        <button onClick={() => handleClickAttachment(index)} style={{ backgroundColor: index === attachementIndex ? '#f0f3f8' : 'white' }}>
                                            {attachement.fileName}
                                        </button>
                                    </React.Fragment>
                                ))}
                        </div>
                        <Status status={status}>{emailSentStatuses[status]}</Status>
                        <div onClick={handleClickArrow}>
                            <span className='material-icons'>{isExtended ? 'expand_less' : 'expand_more'}</span>
                        </div>
                    </div>
                    {body && isExtended && <div>{getBody()}</div>}
                </main>
                <footer>
                    <TextBold color='primary'>{getFinancementLabel()}</TextBold>
                </footer>
            </div>
        </Card>
    );

    function getFinancementLabel() {
        return financement.reduce((acc, financementMode, index) => `${acc}${index > 0 ? ', ' : ''}${financementMode}`, '');
    }
}

function getMarginBottom({ attachments = [] }) {
    return attachments.length > 0 ? '1.2rem' : '0.3rem';
}

const StyledMailCard = styled(UnStyledMailCard)`
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    > header {
        margin: 0.5rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div:nth-child(1) {
            display: flex;
            justify-content: space-between;
            width: 100%;

            > div:nth-child(1) {
                display: flex;
                align-items: center;

                > img {
                    margin-right: 0.3rem;
                    width: 1.5rem;
                    height: 1.5rem;
                }
                > span {
                    margin-right: 0.3rem;
                }
                > span:nth-child(2) {
                    white-space: nowrap;
                }
                > span:nth-child(3) {
                    margin-right: 1rem;
                }
            }

            > div:nth-child(2) {
                white-space: nowrap;
                margin-top: 0.19rem;
                margin-right: 0.3rem;
                display: flex;
                align-items: center;
                float: right;
                > span {
                    margin-right: 0.3rem;
                }
            }
        }
    }

    > main {
        margin: 0rem 1.5rem;
        margin-bottom: ${getMarginBottom};

        > :nth-child(1) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            > :nth-child(1) {
                > button {
                    padding: 0.1rem 0.3rem;
                    border-radius: 24px;
                    border: 1px solid #74798c;
                    background: white;
                    margin-right: 0.6rem;
                    color: #74798c;
                }
            }

            > :nth-child(3) {
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                bottom: 1.2rem;
                text-align: center;
                height: 1.75rem;
                width: 1.75rem;
                border-radius: 24px;
                box-shadow: 0 1px 2px #aaa;
                background-color: #fff;
                padding-top: 0.2rem;
                :hover {
                    cursor: pointer;
                }
            }
        }

        > :nth-child(2) {
            width: 100%;
            max-height: 30rem;
            overflow-y: scroll;
            display: flex;
            justify-content: center;
        }
    }

    > footer {
        background-color: #f6f6f6;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;

        > span {
            margin: 0rem 1.5rem;
        }
    }
`;

export default function MailCard({ actionViewVisible = true, ...restProps }) {
    return <StyledMailCard actionViewVisible={actionViewVisible} {...restProps} />;
}
MailCard.propTypes = {
    testId: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.any,
    financement: PropTypes.arrayOf(PropTypes.string),
    actionView: PropTypes.node,
    actionViewVisible: PropTypes.bool,
    date: PropTypes.string,
    status: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.object)
};
