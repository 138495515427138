import { useEffect } from 'react';

export default function (id, defaultValue) {
    useEffect(() => {
        const input = document.getElementById(id);
        if (isValidValue(defaultValue) && input) {
            const valueAsNumber = Number(input.value);
            const value = Number.isNaN(valueAsNumber) ? input.value : valueAsNumber;
            if (value !== defaultValue) input.value = defaultValue;
        }
    }, [defaultValue]);

    return {};
}

function isValidValue(defaultValue) {
    return ![null, undefined].includes(defaultValue);
}
