import React from 'react';
import PropTypes from 'prop-types';

import TextBold from '../../TextBold';
import Button from '../../Button';
import TextRow from '../../TextRow';
import HouseholdIncomeBonification from './HouseholdIncomeBonification';
import SocialLandlordBonification from './SocialLandlordBonification';
import QPVBonification from './QPVBonification';
import HousingsSummary from './HousingsSummary';

function getQPVBonificationButtonLabel({ qpvBonification } = {}) {
    if (qpvBonification && qpvBonification.qpvName && qpvBonification.qpvCode) return 'Modifier';

    return 'Ajouter un QPV';
}

function Bonifications({
    renovationAddress,
    editable = true,
    shownCustomerPMBonifications = false,
    onCreateOccupant,
    onEditOccupant,
    onDeleteOccupant,
    onEditSocialLandlordBonification,
    onEditQPVBonification
}) {
    return (
        <React.Fragment>
            {renovationAddress.householdIncomeBonification.valid && (
                <React.Fragment>
                    <TextRow className='mt-3'>
                        <TextBold color='primary'>Informations sur les revenus des ménages</TextBold>
                    </TextRow>

                    <HousingsSummary renovationAddress={renovationAddress} />

                    {editable && (
                        <React.Fragment>
                            <HouseholdIncomeBonification renovationAddress={renovationAddress} editable={editable} onEditOccupant={onEditOccupant} onDeleteOccupant={onDeleteOccupant} />
                            <Button variant='outline' onClick={() => onCreateOccupant(renovationAddress)}>
                                Ajouter un occupant
                            </Button>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}

            {shownCustomerPMBonifications && (
                <React.Fragment>
                    {renovationAddress.socialLandlordBonification.valid && (
                        <React.Fragment>
                            <TextRow className='mt-3'>
                                <TextBold color='primary'>Bonification bailleurs sociaux</TextBold>
                            </TextRow>
                            <SocialLandlordBonification renovationAddress={renovationAddress} />
                            {editable && (
                                <Button variant='outline' onClick={() => onEditSocialLandlordBonification(renovationAddress)}>
                                    Modifier
                                </Button>
                            )}
                        </React.Fragment>
                    )}

                    {renovationAddress.qpvBonification.valid && (
                        <React.Fragment>
                            <TextRow className='mt-3'>
                                <TextBold color='primary'>Bonification QPV</TextBold>
                            </TextRow>
                            <QPVBonification renovationAddress={renovationAddress} />
                            {editable && (
                                <Button variant='outline' onClick={() => onEditQPVBonification(renovationAddress)}>
                                    {getQPVBonificationButtonLabel(renovationAddress)}
                                </Button>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

Bonifications.propTypes = {
    renovationAddress: PropTypes.shape({}),
    editable: PropTypes.bool,
    shownCustomerPMBonifications: PropTypes.bool,
    onCreateOccupant: PropTypes.func,
    onEditOccupant: PropTypes.func,
    onDeleteOccupant: PropTypes.func,
    onEditSocialLandlordBonification: PropTypes.func,
    onEditQPVBonification: PropTypes.func
};

export default Bonifications;
