function canShowSeparator(slides, index) {
    return index !== slides.length - 1;
}

function getLeftArrowColor(firstNonOverflowingElementIndex) {
    return isLeftArrowClickable(firstNonOverflowingElementIndex) ? 'primary' : 'arrow';
}

function isLeftArrowClickable(firstNonOverflowingElementIndex) {
    return firstNonOverflowingElementIndex > 0;
}

function getRightArrowColor(lastNonOverflowingElementIndex, slides) {
    return isRightArrowClickable(lastNonOverflowingElementIndex, slides) ? 'primary' : 'arrow';
}

function isRightArrowClickable(lastNonOverflowingElementIndex, slides) {
    return lastNonOverflowingElementIndex !== slides.length - 1;
}

export { canShowSeparator, getLeftArrowColor, isLeftArrowClickable, getRightArrowColor, isRightArrowClickable };
