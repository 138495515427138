import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function getColor({ color }) {
    return color || 'black';
}

function getFontWeight({ textStyle }) {
    return textStyle === 'bold' ? 600 : 400;
}

function UnStyledList({ className, items = [] }) {
    return (
        <ul className={className}>
            {items.map((item, index) => (
                <li key={index}>
                    <span>{item}</span>
                </li>
            ))}
        </ul>
    );
}

const StyledList = styled(UnStyledList)`
    list-style: none;

    > li {
        display: flex;
        position: relative;
        margin-bottom: 0.5rem;

        :last-child {
            margin-bottom: 0;
        }

        ::before {
            content: '';
            position: absolute;
            top: calc((100% - 5px) / 2);
            left: 0;
            width: 5px;
            height: 5px;
            border-radius: 2.5px;
            background: ${(props) => getColor(props)};
        }

        > span {
            margin-left: 12px;
            color: ${(props) => getColor(props)};
            font-weight: ${(props) => getFontWeight(props)};
        }
    }
`;

function List(props) {
    return <StyledList {...props} />;
}

List.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.string,
    textStyle: PropTypes.string
};

export default List;
