import styled from 'styled-components';

import colors from '../../../config/colors';

export default function (UnStyledCustomerStatCard) {
    return styled(UnStyledCustomerStatCard)`
        padding: 1.5rem 2rem;

        > main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: ${colors.primary};
            > div {
                display: flex;
                align-items: center;
                text-align: left;
                > span {
                    font-size: 44px;
                    font-weight: bold;
                    margin-right: 8px;
                }
                > div {
                    display: flex;
                    flex-direction: column;
                    > span {
                        :nth-child(1) {
                            font-size: 14px;
                            font-weight: 500;
                        }
                        :nth-child(2) {
                            font-size: 18px;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    `;
}
