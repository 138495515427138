import React from 'react';

import styleLoader from './utils/style-loader';

const UnStyledLoader = ({ className }) => {
    return (
        <div className={className}>
            <span />
            <span />
            <span />
        </div>
    );
};

const StyledLoader = styleLoader(UnStyledLoader);

export const Loader = (props) => <StyledLoader {...props} />;
