import styled from 'styled-components';

import colors from '../../../config/colors';

export default function (UnStyledSortingArrow) {
    return styled(UnStyledSortingArrow)`
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        transform: ${getTransform};
        cursor: pointer;

        :hover {
            border-bottom-color: ${getBackgroundColor(true)};
        }
    `;
}

function getBackgroundColor(hovered = false) {
    return hovered ? colors.primary : colors.arrow;
}

function getTransform({ variant }) {
    return { asc: 'rotate(180deg)', desc: 'none)' }[variant];
}
