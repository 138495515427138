import styled from 'styled-components';

export default function (UnStyledTextSlider) {
    return styled(UnStyledTextSlider)`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        z-index: 2;

        > svg {
            :first-child {
                margin-right: 0.5rem;
            }

            :last-child {
                margin-left: 0.5rem;
            }
        }

        > main {
            flex: 1;
            overflow: hidden;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            display: flex;

            > * {
                scroll-snap-align: start;
                white-space: nowrap;

                > span {
                    display: inline-block;
                    margin: 0 1rem;
                }
            }
        }
    `;
}
