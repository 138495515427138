function getIconClassName(iconStyle) {
    return { filled: 'material-icons', outline: 'material-icons-outlined' }[iconStyle];
}

function canShowActionIcon(actionIcon, size) {
    return actionIcon && size === 'medium';
}

function isParentComponentTooltip(parentComponent) {
    return parentComponent === 'tooltip';
}

function getDisabled(disabled, parentComponent) {
    return isParentComponentTooltip(parentComponent) ? false : disabled;
}

export { getIconClassName, canShowActionIcon, isParentComponentTooltip, getDisabled };
