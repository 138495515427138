import styled from 'styled-components';

import { getColor } from '../../../utils/component';

export default function (UnStyledStatCard) {
    return styled(UnStyledStatCard)`
        padding: 1.5rem 2rem;

        > main {
            display: flex;
            flex-direction: column;
            color: ${(props) => getTextColor(props)};
        }
    `;
}

function getTextColor({ color, lightMode }) {
    return getColor(color, lightMode);
}
