import { useEffect } from 'react';

export default function (ref, callback, loadOutsideClick = true) {
    useEffect(() => {
        const listener = (event) => {
            const { target } = event;

            if (!loadOutsideClick) return;
            if (!ref.current || ref.current.contains(event.target)) return;
            if (isACascaderElement(target)) return;
            if (target?.innerText?.includes('Créer un produit')) return;

            callback(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, callback]);
}

function isACascaderElement(element) {
    return element?.outerHTML?.includes('cascader');
}
