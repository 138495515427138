import styled from 'styled-components';

export default function (UnStyledArrow) {
    return styled(UnStyledArrow)`
        display: inline-block;
        transform: ${getTransform};
        transition: opacity 200ms ease-in-out;
        cursor: ${getCursor};

        :hover {
            opacity: ${(props) => getOpacity(props, true)};
        }
    `;
}

function getTransform({ variant }) {
    return { left: 'rotate(90deg)', right: 'rotate(-90deg)', top: 'rotate(180deg)', bottom: 'none' }[variant];
}

function getCursor({ clickable }) {
    return clickable ? 'pointer' : 'not-allowed';
}

function getOpacity(props, hovered = false) {
    const { clickable } = props;

    return hovered ? (clickable ? 0.6 : 1) : 1;
}
