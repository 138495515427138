import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidV1 } from 'uuid';

import styleTextSlider from './utils/style-text-slider';
import {
    canShowSeparator,
    getLeftArrowColor,
    isLeftArrowClickable,
    getRightArrowColor,
    isRightArrowClickable
} from './utils';

import Text from './Text';
import { Arrow } from '../arrow';
import useTextSlider from './hooks/useTextSlider';

function UnStyledTextSlider({ className, id, slides, slideComponent, separator }) {
    const defaultId = useMemo(() => id, []);
    const slidesIds = Array.from(slides).map(() => uuidV1());
    const {
        overflowing,
        currentFirstNonOverflowingElementIndex,
        currentLastNonOverflowingElementIndex,
        handlePrev,
        handleNext
    } = useTextSlider(defaultId);

    return (
        <div id={defaultId} className={className}>
            {overflowing && (
                <Arrow
                    variant='left'
                    size='xs'
                    color={getLeftArrowColor(currentFirstNonOverflowingElementIndex)}
                    clickable={isLeftArrowClickable(currentFirstNonOverflowingElementIndex)}
                    onClick={handlePrev}
                />
            )}
            {displaySlides(slides, slidesIds, slideComponent, separator)}
            {overflowing && (
                <Arrow
                    variant='right'
                    size='xs'
                    color={getRightArrowColor(currentLastNonOverflowingElementIndex, slides)}
                    clickable={isRightArrowClickable(currentLastNonOverflowingElementIndex, slides)}
                    onClick={handleNext}
                />
            )}
        </div>
    );
}

function displaySlides(slides, slidesIds, slideComponent, separator) {
    const SlideComponent = slideComponent;

    return (
        <main>
            {slides.map((slide, index) => (
                <React.Fragment key={index}>
                    <SlideComponent id={slidesIds[index]}>
                        {slide}
                        {canShowSeparator(slides, index) && <span>{separator}</span>}
                    </SlideComponent>
                </React.Fragment>
            ))}
        </main>
    );
}

const StyledTextSlider = styleTextSlider(UnStyledTextSlider);

export function TextSlider({ id = uuidV1(), slides = [], slideComponent = Text, separator = '', ...restProps }) {
    return (
        <StyledTextSlider
            id={id}
            slides={slides}
            slideComponent={slideComponent}
            separator={separator}
            {...restProps}
        />
    );
}
TextSlider.propTypes = {
    id: PropTypes.string,
    slides: PropTypes.arrayOf(PropTypes.string),
    slideComponent: PropTypes.elementType,
    separator: PropTypes.node
};
