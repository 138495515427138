import styled from 'styled-components';

import colors from '../../config/colors';

export default styled.span`
    display: block;
    text-align: center;
    font-weight: 500;
    color: ${colors.primary};
`;
