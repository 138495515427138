import React, { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';

import styleTooltip from './utils/style-tooltip';
import { joinClassNames } from '../../utils/array';
import { TOOLTIP_CONTENT_TEST_ID } from '../../utils/tests-ids';

import useTooltip from './hooks/useTooltip';

const UnStyledTooltip = ({ className, content, htmlFor, style, trigger, children, onClick }) => {
    const { visibility, handleClick, handleMouseEnter, handleMouseLeave } = useTooltip(trigger, content);
    const childrenProps = isCustomComponent(children) ? { parentComponent: 'tooltip' } : {};
    if (onClick) childrenProps.onClick = onClick;

    return (
        <div role='tooltip' aria-label={htmlFor} className={joinClassNames(className, visibility)} style={style} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div data-testid={TOOLTIP_CONTENT_TEST_ID}>
                <div data-content={content} />
            </div>
            {isValidElement(children) && cloneElement(children, childrenProps)}
        </div>
    );
};

function isCustomComponent(children) {
    return typeof children.type === 'function';
}

const StyledTooltip = styleTooltip(UnStyledTooltip);

export const Tooltip = ({ fluid = false, placement = 'left', trigger = 'hover', size = 'md', type = 'info', ...restProps }) => {
    return <StyledTooltip fluid={fluid} placement={placement} trigger={trigger} size={size} type={type} {...restProps} />;
};
Tooltip.propTypes = {
    fluid: PropTypes.bool,
    content: PropTypes.string,
    htmlFor: PropTypes.string,
    placement: PropTypes.oneOf(['left', 'top', 'bottom', 'right']),
    trigger: PropTypes.oneOf(['hover', 'click']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    type: PropTypes.oneOf(['info', 'warning', 'error']),
    style: PropTypes.shape({})
};
