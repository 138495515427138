import { useState } from 'react';

export default function (slides, onSlideChange) {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [hasPageChangedOnce, setHasPageChangedOnce] = useState(false);

    function handleSlideChange({ page }) {
        const targetSlideIndex = page - 1;
        setActiveSlideIndex(targetSlideIndex);
        if (onSlideChange) onSlideChange(slides[targetSlideIndex], page);
        if (!hasPageChangedOnce) setHasPageChangedOnce(true);
    }

    return {
        activeSlideIndex,
        hasPageChangedOnce,
        handleSlideChange
    };
}
