import { useState, useEffect } from 'react';
import { isDateValue } from '../../../utils';

import itemCountPerPageOptions from '../utils/item-count-per-page-options';
import { sortingOrders } from '../../../utils/enums';

export default function (
    dataSource = [],
    onPageChangeCallback,
    onItemsCountPerPageChangeCallback,
    shouldComponentHandleItemsNumber = true,
    defaultCurrentItemsCountPerPage = itemCountPerPageOptions[0].value
) {
    const [currentItems, setCurrentItems] = useState([]);
    const [currentItemsCountPerPage, setCurrentItemsCountPerPage] = useState(defaultCurrentItemsCountPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);

    function updateCurrentItems(formattedDataSource = [], page = currentPage, itemsCountPerPage = currentItemsCountPerPage) {
        if (!shouldComponentHandleItemsNumber) {
            setCurrentItems(formattedDataSource);
            return;
        }
        const startIndex = (page - 1) * itemsCountPerPage;
        const endIndex = startIndex + itemsCountPerPage;
        setCurrentItems(formattedDataSource.slice(startIndex, endIndex));
        setCurrentPage(page);
        if (itemsCountPerPage !== currentItemsCountPerPage) setCurrentItemsCountPerPage(itemsCountPerPage);
    }

    function updatePageCount(formattedDataSource = [], itemsCountPerPage = currentItemsCountPerPage) {
        const pagesCountDecimalValue = formattedDataSource.length / itemsCountPerPage;
        const pagesCountRoundedValue = formattedDataSource.length % itemsCountPerPage === 0 ? pagesCountDecimalValue : Math.ceil(pagesCountDecimalValue);
        setPagesCount(pagesCountRoundedValue);
    }

    function handlePageChange({ page }) {
        updateCurrentItems(dataSource, page, currentItemsCountPerPage);
        onPageChangeCallback && onPageChangeCallback({ page });
    }

    function handleItemsCountPerPageChange(itemsCountPerPage) {
        updateCurrentItems(dataSource, 1, itemsCountPerPage);
        updatePageCount(dataSource, itemsCountPerPage);
        onItemsCountPerPageChangeCallback && onItemsCountPerPageChangeCallback(itemsCountPerPage);
    }

    function handleSortAscend({ dataIndex, format }) {
        applySorting({ dataIndex, format, sortingOrder: sortingOrders.ASCEND });
    }

    function handleSortDesend({ dataIndex, format }) {
        applySorting({ dataIndex, format, sortingOrder: sortingOrders.DESCEND });
    }

    function applySorting({ dataIndex, format, sortingOrder }) {
        const formattedDataSource = dataSource.sort((prevItem, nextItem) => {
            const prevItemValue = formatItemValue(prevItem, dataIndex, format);
            const nextItemValue = formatItemValue(nextItem, dataIndex, format);
            if (prevItemValue > nextItemValue) return { [sortingOrders.ASCEND]: 1, [sortingOrders.DESCEND]: -1 }[sortingOrder];
            if (prevItemValue < nextItemValue) return { [sortingOrders.ASCEND]: -1, [sortingOrders.DESCEND]: 1 }[sortingOrder];

            return 0;
        });
        updateCurrentItems(formattedDataSource, 1, currentItemsCountPerPage);
    }

    function formatItemValue(item, dataIndex, format) {
        let formattedItemValue = format ? format(item[dataIndex]) : item[dataIndex];
        if (isDateValue(formattedItemValue)) formattedItemValue = Date.parse(formattedItemValue);

        return formattedItemValue;
    }

    useEffect(() => {
        updateCurrentItems(dataSource);
        updatePageCount(dataSource);
    }, [dataSource]);

    return {
        currentItems,
        currentItemsCountPerPage,
        currentPage,
        pagesCount,
        handlePageChange,
        handleItemsCountPerPageChange,
        handleSortAscend,
        handleSortDesend
    };
}
