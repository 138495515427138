import styled from 'styled-components';

import colors from '../../../config/colors';
import dimensions from '../../../config/dimensions';
import zIndices from '../../../config/z-indices';

export default function (UnStyledAutocompletePopover) {
    return styled(UnStyledAutocompletePopover)`
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        max-height: 12.5rem;
        overflow: hidden;
        margin: 0.5rem 0;
        background: ${colors.white};
        border-radius: ${dimensions.radius};
        z-index: ${zIndices.autocomplete};
        box-shadow: 0 0 5px 0 rgba(144, 177, 225, 0.38);

        > ul {
            list-style: none;
            overflow-y: auto;
            margin: 0;

            scrollbar-width: 0.4rem;
            scrollbar-color: ${colors.scrollbar.default} ${colors.white};
            ::-webkit-scrollbar {
                width: 0.4rem;
            }
            ::-webkit-scrollbar-track {
                background: ${colors.white};
            }
            ::-webkit-scrollbar-thumb {
                background: ${colors.scrollbar.default};
                border-radius: 0.6rem;
            }
            ::-webkit-scrollbar-thumb:hover {
                background: ${colors.scrollbar.hover};
            }

            > li {
                padding: 0.75rem 1rem;
                color: ${colors.black};
                cursor: pointer;
                transition: all 200ms ease-in;

                &:hover {
                    background: #f0f3f8;
                }
            }
        }
        > footer {
            padding: 1rem;
            background: ${colors.white};
        }
    `;
}
