import styled from 'styled-components';
import dimensions from '../../../config/dimensions';
import colors from '../../../config/colors';

export default function (UnStyledAutocomplete) {
    return styled(UnStyledAutocomplete)`
        ${getStyle}
    `;
}

function getStyle(props) {
    return props.searchIcon
        ? `
    position: relative;
    border-radius: 10px;
    border: 1.5px solid ${colors.primary};
    box-shadow: 0px 0px 3px 0px rgba(63, 63, 63, 0.25);
    background-color: #f5f6fa;

    > div {
        display: flex;
        align-items: center;
        border-radius: 10px;
        > div {
            width: 100%;
            border-radius: 10px;
            > input {
                background-color: #f5f6fa !important;
                border-radius: inherit !important;
                border: 0 !important;
            }
        }
        > .icon {
            border-radius: 0 ${dimensions.radius} ${dimensions.radius} 0;
            min-height: 37.2px;
            width: 1.9rem;
            padding-top: 0.5rem;
            padding-left: 0.3rem;
            > img {
                height: 1.1rem;
                width: 1.1rem;
            }
        }
    }
`
        : `
position: relative;

`;
}
