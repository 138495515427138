import React, { useRef } from 'react';
import useTooltip from '../tooltip/hooks/useTooltip';
import { joinClassNames } from '../../utils/array';
import stylePopover from './utils/style-popover';
import { POPOVER_CONTENT_TEST_ID } from '../../utils/tests-ids';
import PropTypes from 'prop-types';

export const UnStyledPopover = ({ className, htmlFor, style, trigger, render = null, onClick, children }) => {
    const { visibility, handleClick, handleMouseEnter, handleMouseLeave } = useTooltip(trigger, 'whatever');
    const childrenRef = useRef();
    const popoverRef = useRef();
    const childrenProps = { parentComponent: 'popover' };
    if (onClick) childrenProps.onClick = onClick;

    return (
        <div role='popover' aria-label={htmlFor} className={joinClassNames(className, visibility)} style={style} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div data-testid={POPOVER_CONTENT_TEST_ID} ref={popoverRef}>
                {render && render()}
            </div>
            <div ref={childrenRef}>{children}</div>
        </div>
    );
};

const StyledPopover = stylePopover(UnStyledPopover);

export const Popover = ({
    fluid = false,
    placement = 'right',
    trigger = 'hover',
    size = 'md',
    type = 'info',
    popoverWidth = '750px',
    adjustPositioningConstant = 1,
    isArrowCentered = false,
    ...restProps
}) => {
    return (
        <StyledPopover
            fluid={fluid}
            placement={placement}
            trigger={trigger}
            size={size}
            isArrowCentered={isArrowCentered}
            type={type}
            popoverWidth={popoverWidth}
            adjustPositioningConstant={adjustPositioningConstant}
            {...restProps}
        />
    );
};

Popover.propTypes = {
    fluid: PropTypes.bool,
    content: PropTypes.string,
    popoverWidth: PropTypes.string,
    adjustPositioningConstant: PropTypes.number,
    isArrowCentered: PropTypes.bool,
    htmlFor: PropTypes.string,
    placement: PropTypes.oneOf(['left', 'top', 'bottom', 'right']),
    trigger: PropTypes.oneOf(['hover', 'click']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    type: PropTypes.oneOf(['info', 'warning', 'error']),
    style: PropTypes.shape({})
};
