import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Card } from './card';

const UnStyledTitleCard = ({ className, title, children }) => {
    return (
        <Card>
            <div className={className}>
                <header>
                    <span>{title}</span>
                </header>
                <main>{children}</main>
            </div>
        </Card>
    );
};

const StyledTitleCard = styled(UnStyledTitleCard)`
    > header {
        padding: 1rem;
        position: relative;
        background: #e5e8f3;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 5px 5px 0 0;

        ::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #d6dce9;
        }

        > span {
            font-size: 0.9rem;
            font-weight: 600;
            color: var(--color-primary);
        }
    }

    > main {
        padding: 1.5rem;
    }
`;

const TitleCard = (props) => <StyledTitleCard {...props} />;

TitleCard.propTypes = {
    title: PropTypes.string
};

TitleCard.defautlProps = {
    title: ''
};

export default TitleCard;
