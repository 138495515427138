import React from 'react';
import PropTypes from 'prop-types';

import styleStatCardTwo from './utils/style-stat-card-two';

const UnStyledStatCardTwo = ({ className, title, value, actionView }) => {
    return (
        <main className={className}>
            <span>{value}</span>
            <span>{title}</span>
            {actionView && <section>{actionView}</section>}
        </main>
    );
};

const StyledStatCardTwo = styleStatCardTwo(UnStyledStatCardTwo);

export const StatCardTwo = (props) => <StyledStatCardTwo {...props} />;
StatCardTwo.propTypes = {
    color: PropTypes.string,
    lightMode: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actionView: PropTypes.node
};
