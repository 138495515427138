export default {
    primary: '#1d3495',
    primaryLight0: '#E5E8F3',
    primaryLight1: '#1d34951c',
    primaryLight2: '#4c93e3',
    primaryLight3: '#0026c8',
    primaryDark: '#02197a',
    primaryDark0: '#1d3495c0',
    primaryDark1: '#1d3495c0',
    primaryDark2: '#176ac7',
    primaryDark3: '#001c96',
    primaryOutlineLight: '#1D349519',
    secondary: '#31AEAC',
    secondaryLight1: '#31aeac33',
    secondaryLight2: '#03828035',
    secondaryLight3: '#31aeac',
    secondaryDark: '#097877',
    secondaryDark1: '#31aeacf8',
    secondaryDark2: '#038280ca',
    secondaryOutlineLight: '#f0ede9',
    light: '#ffffff',
    lightDark: '#ffffff',
    red: '#ffffff',
    redDark: '#ffffff',
    black: '#000000',
    white: '#ffffff',
    disabled: '#c5c5c5',
    info: '#ffffff',
    warning: '#e2b844',
    error: '#e46526',
    fieldError: '#B7090F',
    input: {
        label: '#74798c',
        placeholder: '#99a3ac',
        border: '#e4e4e4'
    },
    scrollbar: {
        default: '#99a3ac',
        hover: '#b30000'
    },
    popover: {
        item: '#f0f3f8'
    },
    arrow: '#74798C',
    sortingArrow: '#99a3ac',
    table: {
        header: '#F8F8F8'
    },
    housingSituations: {
        classic: '#31aeac',
        precarity: '#0026c8',
        greatPrecarity: '#33f1e6'
    }
};

export const outlines = {
    primaryLight: '#758CDC'
};
