import React from 'react';
import PropTypes from 'prop-types';

import styleImage from './utils/style-image';
import { getVisibilityClassName } from './utils/index';
import { joinClassNames } from '../../utils/array';

import useImage from './hooks/useImage';

function UnStyledImage({ className, style = {}, src, alt, onLoad, onError }) {
    const { visibile, handleLoad, handleError } = useImage(onLoad, onError);

    return src ? <img className={joinClassNames(className, getVisibilityClassName(visibile))} style={style} src={src} alt={alt} onLoad={handleLoad} onError={handleError} /> : <React.Fragment />;
}

const StyledImage = styleImage(UnStyledImage);

export function Image({ alt = 'Image', ...restProps }) {
    return <StyledImage alt={alt} {...restProps} />;
}
Image.propTypes = {
    className: PropTypes.string,
    style: PropTypes.shape({}),
    src: PropTypes.string,
    alt: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func
};
