import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { v1 as uuid } from 'uuid';

const defaultId = uuid();

const Radio = ({ className, id, name, label, dataCy, defaultChecked, checked, clickable, onChange, disabled }) => {
    const finalId = id || defaultId;

    return (
        <div className={className}>
            {checked ? (
                <React.Fragment>
                    <input type='radio' data-cy={dataCy} name={name} id={finalId} onChange={onChange} disabled={disabled} checked={checked} />
                    <label htmlFor={finalId}>
                        <span />
                        <span>{label}</span>
                    </label>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <input type='radio' data-cy={dataCy} name={name} id={finalId} onChange={onChange} defaultChecked={defaultChecked} disabled={disabled} />
                    <label htmlFor={finalId}>
                        <span />
                        <span>{label}</span>
                    </label>
                </React.Fragment>
            )}
        </div>
    );
};

Radio.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultChecked: PropTypes.bool,
    checked: PropTypes.any,
    clickable: PropTypes.bool
};

Radio.defaultProps = {
    defaultChecked: false,
    checked: null,
    clickable: true
};

export default styled(Radio)`
    display: inline-block;
    width: 100%;
    position: relative;

    > input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:checked + label {
            color: ${(props) => (props.clickable ? 'var(--color-primary)' : 'var(--color-input-label)')};

            > span:first-child::after {
                content: '';
                background: var(--color-primary);
            }
        }
    }

    > label {
        margin: 0;
        display: flex;
        align-items: ${getAlignItems};
        justify-content: space-between;
        cursor: ${(props) => (props.clickable ? 'pointer' : 'initial')};
        font-size: var(--input-size);
        margin-top: 4px;

        > span:first-child {
            display: inline-block;
            width: 1.25rem;
            height: 1.25rem;
            border: 1px solid var(--color-input-border-default);
            border-radius: 50%;
            margin-right: 0.35rem;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                border: none;
                border-radius: 50%;
                background: var(--color-white);
                transition: all 300ms ease-out;
            }
        }

        > span:last-child {
            display: inline-block;
            flex: 1;
            color: #000000;
        }
    }
`;

function getAlignItems({ verticalAlign }) {
    return { center: 'center', start: 'flex-start' }[verticalAlign];
}
