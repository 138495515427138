import { useState, useEffect } from 'react';

export default function (options, onSelectOption) {
    const [selectedOption, setSelectedOption] = useState({});
    useEffect(() => {
        if (options.length > 0) setSelectedOption(options[0]);
    }, [options]);

    function handleSelect(option) {
        setSelectedOption(option);
        if (onSelectOption) onSelectOption(option);
    }

    return {
        selectedOption,
        handleSelect
    };
}
