import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { iconSizes } from '../../config/sizes';
import { joinClassNames } from '../../utils/array';
import colors from '../../config/colors';

function UnStyledIcon({ className, variant, name, label, onClick }) {
    const additionalProps = onClick ? { role: 'button', 'aria-label': label } : {};

    function handleClick() {
        if (onClick) onClick();
    }

    return (
        <span className={joinClassNames(className, getClassName(variant, onClick))} onClick={handleClick} {...additionalProps}>
            {name}
        </span>
    );
}

function getClassName(variant, onClick) {
    const iconClassName = { outlined: 'material-icons-outlined', filled: 'material-icons' }[variant];

    return joinClassNames(iconClassName, onClick && 'clickable');
}

const StyledIcon = styled(UnStyledIcon)`
    font-size: ${getFontSize} !important;
    color: ${getColor};

    &.clickable {
        cursor: pointer;
        transition: all 200ms ease-in-out;

        :hover {
            transform: scale(1.1);
            opacity: 0.75;
        }
    }
`;

function getFontSize({ size }) {
    return iconSizes[size];
}

function getColor({ color }) {
    return colors[color];
}

export function Icon({ variant = 'outlined', size = 'md', color = 'primary', ...restProps }) {
    return <StyledIcon variant={variant} size={size} {...restProps} color={color} />;
}

Icon.propTypes = {
    name: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'filled']),
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    color: PropTypes.string,
    onClick: PropTypes.func
};
