import styled from 'styled-components';

export default function (UnStyledTableHeader) {
    return styled(UnStyledTableHeader)`
        display: flex;
        align-items: center;
        justify-content: space-between;

        > section {
            display: flex;
            align-items: center;

            :first-child {
                > * {
                    margin-right: 0.5rem;
                }

                > *:last-child {
                    margin-right: 0;
                }
            }
        }
    `;
}
