import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { LABEL_BUTTON_DELETE_UPLOADED_FILE, LABEL_BUTTON_UPLOAD_FILE, LABEL_LINK_UPLOADED_FILE } from '../../utils/form_labels/common';
import { UPLOAD_INPUT_TEST_ID } from '../../utils/tests-ids';
import { isNonEmptyString } from '../../utils';

import UploadStyled from './Upload.styled';
import { Button } from '../buttons';
import { FieldLabel } from '../input';
import { Icon } from '../icon';

export function Upload({ label, labelStyle = 'normal', fileUrl, disabled = false, accept = ['application/pdf', 'image/jpg', 'image/png', 'image/jpeg'], onChange, onDelete, isDeletable }) {
    const props = { labelStyle };
    const fileInputRef = useRef();

    function handleChange({ target }) {
        if (isNonEmptyString(fileUrl)) return;

        const { files } = target;
        if (files && files.length > 0) {
            const file = files[0];
            fileInputRef.current.value = null;
            if (onChange) onChange({ file });
        }
    }

    function handleClick() {
        fileInputRef.current.click();
    }

    function handleDelete() {
        if (onDelete) onDelete();
    }

    return (
        <UploadStyled {...props}>
            <input data-testid={UPLOAD_INPUT_TEST_ID} ref={fileInputRef} type='file' accept={accept} onChange={handleChange} />
            <main>
                {label && <FieldLabel>{label}</FieldLabel>}
                <Button variant='outline' icon='upload' iconStyle='outline' disabled={disabled || isNonEmptyString(fileUrl)} onClick={handleClick}>
                    {LABEL_BUTTON_UPLOAD_FILE}
                </Button>
            </main>
            {isNonEmptyString(fileUrl) && (
                <footer>
                    <a href={fileUrl} target='_blank' rel='noopener noreferrer'>
                        {LABEL_LINK_UPLOADED_FILE}
                    </a>
                    {isDeletable && <Icon name='delete' size='sm' label={LABEL_BUTTON_DELETE_UPLOADED_FILE} onClick={handleDelete} />}
                </footer>
            )}
        </UploadStyled>
    );
}
Upload.propTypes = {
    label: PropTypes.string,
    labelStyle: PropTypes.string,
    fileUrl: PropTypes.string,
    disabled: PropTypes.bool,
    accept: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    isDeletable: PropTypes.bool
};
