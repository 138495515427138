export default {
    CLASSIC: {
        SHORT: 'C',
        LONG: 'classique'
    },
    PRECARITY: {
        SHORT: 'M',
        LONG: 'modeste'
    },
    GREAT_PRECARITY: {
        SHORT: 'PE',
        LONG: 'précarité énergétique'
    }
};
