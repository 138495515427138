import { useState, useEffect } from 'react';

export default function ({ defaultValue, min }) {
    const [currentValue, setCurrentValue] = useState();
    const [currentValueAsText, setCurrentValueAsText] = useState('');

    useEffect(() => {
        if (defaultValue >= min) {
            setCurrentValue(defaultValue);
            setCurrentValueAsText(String(defaultValue));
        }
    }, [defaultValue]);

    return {
        currentValue,
        setCurrentValue,
        currentValueAsText,
        setCurrentValueAsText
    };
}
