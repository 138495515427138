import { sortDocumentsByPropertyAscendant } from '.';
import { housingSituations, translationModes } from './enums';
import { housingSituationsTranslations } from './translations';

function groupHousingsBySituation(renovationAddress) {
    const { housings = [] } = renovationAddress.householdIncomeBonification;
    const housingsBySituation = housings.reduce((result, housing) => {
        (result[housing.situation] = result[housing.situation] || []).push(housing);

        return result;
    }, {});
    const groupedHousings = Object.entries(housingsBySituation).map(([housingSituation, housings]) => {
        return { housingSituation, count: housings.length, displayOrder: getHousingSituationDisplayOrder(housingSituation) };
    });

    return sortByDisplayOrderAscending(groupedHousings);
}

function getHousingSituationDisplayOrder(housingSituation) {
    return {
        [housingSituations.GREAT_PRECARITY]: 1,
        [housingSituations.PRECARITY]: 2,
        [housingSituations.CLASSIC]: 3
    }[housingSituation];
}

function sortByDisplayOrderAscending(groupedHousings = []) {
    return sortDocumentsByPropertyAscendant({ documents: groupedHousings, property: 'displayOrder' });
}

function formatHousingSituation(housingSituation, mode = translationModes.SHORT) {
    const translations = housingSituationsTranslations[housingSituation];

    return translations ? translations[mode] : '';
}

function formatHousingCount(count) {
    return `${count} ménage`.concat(count > 1 ? 's' : '').concat(' : ');
}

export default { groupHousingsBySituation, formatHousingSituation, formatHousingCount };
