import React from 'react';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import colors from '../config/colors';
import utils from '../utils';

const UnStyledContractCard = ({ className, value, label }) => {
    return (
        <div className={className}>
            <div>
                <h2>{utils.formatPriceValue(value)}</h2>
                {label && <p>{label}</p>}
            </div>
        </div>
    );
};

const ContractCard = styled(UnStyledContractCard)`
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    height: 67px;
    border: 1px solid #d6dce9;
    border-radius: 5px;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        color: ${colors.primary};

        h2 {
            font-weight: 600;
            font-size: 1.15rem;
            margin: 0;
            line-height: 1;
        }

        p {
            margin: 0.25rem 0 0 0;
            font-size: 12px;
        }
    }
`;

ContractCard.propTypes = {
    value: PropTypes.number,
    label: PropTypes.string
};

export default ContractCard;
