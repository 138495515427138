import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function UnStyledTextRow({ className, children }) {
    return <div className={className}>{children}</div>;
}

const StyledTextRow = styled(UnStyledTextRow)`
    margin-bottom: 0.3rem;
    display: ${getDisplay};

    > * {
        display: ${getChildDisplay};

        :nth-child(1) {
            margin-right: 0.25rem;
        }
    }
`;

function getDisplay({ inlineContent }) {
    return inlineContent ? 'block' : 'flex';
}

function getChildDisplay({ inlineContent }) {
    return inlineContent ? 'inline' : 'initial';
}

export default function TextRow({ inlineContent = false, ...restProps }) {
    return <StyledTextRow inlineContent={inlineContent} {...restProps} />;
}
TextRow.propTypes = {
    inlineContent: PropTypes.bool
};
