export const fontSizes = {
    xs: '11px',
    sm: '12px',
    md: '13px',
    lg: '1.125rem',
    xl: '1.75rem',
    '2xl': '2.1rem',
    '3xl': '2.75rem'
};

export const iconSizes = { xs: '18px', sm: '20px', md: '24px', lg: '32px', xl: '60px' };
