import React from 'react';

import styleTableActions from './utils/style-table-actions';

function UnStyledTableActions({ className, children }) {
    return <div className={className}>{children}</div>;
}

const StyledTableActions = styleTableActions(UnStyledTableActions);

export function TableActions(props) {
    return <StyledTableActions {...props} />;
}
