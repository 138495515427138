import { useState } from 'react';

export default function (onLoad, onError) {
    const [visibile, setVisible] = useState(false);

    function handleLoad() {
        setVisible(true);
        if (onLoad) onLoad();
    }

    function handleError() {
        setVisible(false);
        if (onError) onError();
    }

    return {
        visibile,
        handleLoad,
        handleError
    };
}
