import { gendersTranslations } from '../../../utils/translations';
import TextBold from '../../TextBold';
import Text from '../../Text';
import smallEyeIcon from '../../../assets/img/picto_small_vue.svg';
import smallEditIcon from '../../../assets/img/picto_smal_edit.svg';
import smallCrossIcon from '../../../assets/img/picto_smal_cross.svg';
import renovationAddressHelper from '../../../utils/renovation-address.helper';
import { translationModes } from '../../../utils/enums';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const OccupantGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr 1fr [end];
    grid-template-rows: auto;
    grid-template-areas:
        'name taxheader .'
        'fiscalnumber  taxnoticereference buttons'
        'housingsituation';
    gap: 0.5rem;
    align-items: center;
    padding: 0.65rem 0.5rem;
    border: 1px solid #d6dce9;
    border-radius: 5px;
    margin-bottom: 1rem;
`;

const HouseholIncomeButtons = styled.div`
    justify-self: end;
    img {
        margin-right: 0.7rem;
    }
`;

const HouseholdSituation = styled.div`
    grid-column-start: 1;
    grid-column-end: end;
`;

export const Occupant = ({ occupant, housing, onEditOccupant, editable = true, onDeleteOccupant, withSituation = true, canBeSeen = true }) => {
    const formatOccupantGender = (occupant) => {
        const translations = gendersTranslations[occupant.gender];
        return translations ? translations.SHORT : '';
    };

    const getYear = (date) => {
        if (!date) return '';
        const dateObject = new Date(date);
        const year = dateObject.getFullYear();
        return year;
    };

    const displayOccupantActions = () => editable || canBeSeen;

    return (
        <OccupantGrid key={occupant.uuid}>
            <div>
                <TextBold>
                    {window.innerWidth > 1600 && `${formatOccupantGender(occupant)} `} {occupant.lastName}
                </TextBold>
                <TextBold> {occupant.firstName}</TextBold>
            </div>
            <div>
                <TextBold>{`Avis d'imposition ${getYear(occupant.taxNoticeIssueDate)}`}</TextBold>
                {occupant.taxNoticeExpired && <span style={{ color: 'red' }}> (expiré)</span>}
            </div>
            <div />
            <div>
                <Text.Label>Numéro fiscal : </Text.Label>
                {occupant.taxNoticeNumber}
            </div>
            <div>
                <Text.Label>Référence de l'avis : </Text.Label>
                {occupant.taxNoticeReference}
            </div>
            <HouseholIncomeButtons>
                {displayOccupantActions() && (
                    <div>
                        {canBeSeen && occupant.taxNoticeFileDownloadUrl && (
                            <a href={occupant.taxNoticeFileDownloadUrl} target='_blank' rel='noopener noreferrer'>
                                <img src={smallEyeIcon} alt='View occupant' />
                            </a>
                        )}
                        {editable && (
                            <Fragment>
                                <img src={smallEditIcon} alt='Edit occupant' onClick={onEditOccupant} />
                                <img src={smallCrossIcon} alt='Delete occupant' onClick={onDeleteOccupant} />
                            </Fragment>
                        )}
                    </div>
                )}
            </HouseholIncomeButtons>
            {withSituation && (
                <HouseholdSituation>
                    <Text.Label>Situation du ménage : </Text.Label>
                    {renovationAddressHelper.formatHousingSituation(housing.situation, translationModes.LONG)}
                </HouseholdSituation>
            )}
        </OccupantGrid>
    );
};

Occupant.propTypes = {
    occupant: PropTypes.shape({}),
    housingNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onEditOccupant: PropTypes.func,
    editable: PropTypes.bool,
    canBeSeen: PropTypes.bool,
    onDeleteOccupant: PropTypes.func
};
