function capitalizePersonName(value) {
    if (typeof value !== 'string') return value;

    return value.trim().split(' ').map(capitalize).join(' ');
}

function capitalize(value) {
    if (typeof value !== 'string') return value;

    return value.length > 1 ? value[0].toUpperCase().concat(value.substr(1).toLowerCase()) : value.toUpperCase();
}

function toUpperCase(value) {
    if (typeof value !== 'string') return value;

    return value.toUpperCase();
}

function toLowerCase(value) {
    if (typeof value !== 'string') return value;

    return value.toLowerCase();
}

export { capitalizePersonName, capitalize, toUpperCase, toLowerCase };
